// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-service-index-it-js": () => import("./../src/templates/service/index.it.js" /* webpackChunkName: "component---src-templates-service-index-it-js" */),
  "component---src-templates-service-index-js": () => import("./../src/templates/service/index.js" /* webpackChunkName: "component---src-templates-service-index-js" */),
  "component---src-templates-service-index-hr-js": () => import("./../src/templates/service/index.hr.js" /* webpackChunkName: "component---src-templates-service-index-hr-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-hr-js": () => import("./../src/pages/about/index.hr.js" /* webpackChunkName: "component---src-pages-about-index-hr-js" */),
  "component---src-pages-about-index-it-js": () => import("./../src/pages/about/index.it.js" /* webpackChunkName: "component---src-pages-about-index-it-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contacts-index-hr-js": () => import("./../src/pages/contacts/index.hr.js" /* webpackChunkName: "component---src-pages-contacts-index-hr-js" */),
  "component---src-pages-contacts-index-it-js": () => import("./../src/pages/contacts/index.it.js" /* webpackChunkName: "component---src-pages-contacts-index-it-js" */),
  "component---src-pages-contacts-index-js": () => import("./../src/pages/contacts/index.js" /* webpackChunkName: "component---src-pages-contacts-index-js" */),
  "component---src-pages-cookie-policy-index-hr-js": () => import("./../src/pages/cookie-policy/index.hr.js" /* webpackChunkName: "component---src-pages-cookie-policy-index-hr-js" */),
  "component---src-pages-cookie-policy-index-it-js": () => import("./../src/pages/cookie-policy/index.it.js" /* webpackChunkName: "component---src-pages-cookie-policy-index-it-js" */),
  "component---src-pages-cookie-policy-index-js": () => import("./../src/pages/cookie-policy/index.js" /* webpackChunkName: "component---src-pages-cookie-policy-index-js" */),
  "component---src-pages-index-hr-js": () => import("./../src/pages/index.hr.js" /* webpackChunkName: "component---src-pages-index-hr-js" */),
  "component---src-pages-index-it-js": () => import("./../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-index-hr-js": () => import("./../src/pages/privacy-policy/index.hr.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-hr-js" */),
  "component---src-pages-privacy-policy-index-it-js": () => import("./../src/pages/privacy-policy/index.it.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-it-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */)
}

